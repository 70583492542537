type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const customEvent = (array:any) => {
  // console.log(array)
  let dataLayer = array.dataLayer ? array.dataLayer : null;
  dataLayer.event = array.dataLayerName ? array.dataLayerName : null;
  // console.log(dataLayer)
  if(dataLayer){
    window.dataLayer.push(dataLayer);
  }
}