import React, { ReactNode, useState, useEffect } from 'react';
import { useAtom } from "jotai";
import { noScrollAtom } from '../store/Layout';
import useBodyClass from '../hooks/UseBodyClass';

const useModal = () => {
  const [visible, setVisibility] = useState(false);
  const [noScroll, setNoScroll] = useAtom(noScrollAtom);

  useBodyClass();

  const openModal = () => {
    setVisibility(true);
    setNoScroll(true);
  };

  const closeModal = () => {
    setVisibility(false);
    setNoScroll(false);
  };

  const Modal = ({
    title = null,
    size = null,
    children,
    header = false,
    styles = null
  }: {
    title?: string | null;
    size?: string | null;
    children: ReactNode;
    header?: boolean;
    styles?: string | null;
  }) => {

    return (
      <>
        <div className={`modal ${styles || ''} ${visible ? 'modal--open' : ''}`}>
          <div className={`modal__contents ${size ? `modal__contents--${size}` : 'modal__contents--md'}`}>
            { header && 
            <header className="modal__header">
              { title &&
              <h2 className="modal__title text--l text--left text--bold">{title}</h2>
              }
              <button className="modal__close-button" onClick={closeModal}>
                <img src="../assets/img/icon_cross_02.svg" alt="" />
              </button>
            </header>
            }
            <div className="modal__body">{children}</div>
          </div>
        </div>
      </>
    );

  };

  return { Modal, openModal, closeModal };
};

export default useModal;
