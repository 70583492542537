// components/UserList.tsx

import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { dialogAtom } from '../store/Components';
import useDialog from "../hooks/UseDialog";

// interface DialogProps {
//   close: () => void;
// }

function Dialog() {
  //const [users, setUsers] = useState<User[]>([]);

  const { close } = useDialog();
  const [ dialog, setDialog ] = useAtom(dialogAtom);

  useEffect(() => {
    // getUsers()
    //   .then(data => setUsers(data))
    //   .catch(error => console.log(error));
  }, []);

  return (
    <>
      <div className={`dialog ${dialog.visible ? 'dialog--open' : ''}`}>
        <div className="dialog__base"></div>
        <div className="dialog__content">
          <header className="dialog__header">
            <button className="dialog__close-button" onClick={close}>
              <img src="/assets/img/icon_cross_02.svg" alt="" />
            </button>
            <p className="dialog__title">{dialog.title}</p>
          </header>
          <div className="dialog__body">{dialog.body}</div>
          <div className="dialog__actions">
            <button type="button" onClick={()=>dialog.confirmAction('cancel')} className="button--secondary">
              いいえ
            </button>
            <button type="button" onClick={()=>dialog.confirmAction('ok')} className="button--primary">
              {dialog.confirmText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dialog;