import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { get } from '../api/ApiClient';
import LocalesSelectButton from '../components/LocalesSelectButton';

function Header() {

  const navigate = useNavigate();

  useEffect(() => {
    //when click .site-nav__toggle-child-menu-button, toggle class of .site-nav__child-menu
    const toggleChildMenuButton = document.querySelectorAll('.site-nav__toggle-child-menu-button');
    //const childMenu = document.querySelector('.site-nav__child-menu');

    if(toggleChildMenuButton !== null) {
      //loop through all .site-nav__toggle-child-menu-button
      //and add event listener to each of them
      toggleChildMenuButton.forEach((button) => {
        if(button && button.parentElement) {
          button.addEventListener('click', () => {
            const childMenu = button.parentElement?.querySelector('.site-nav__child-menu');
            if(childMenu) {
              childMenu.classList.toggle('site-nav__child-menu--open');
            }
          });
        }
      });
    }

    //when click anywhere outside of .site-nav__child-menu, close it
    document.addEventListener('click', (event) => {
      const childMenus = document.querySelectorAll('.site-nav__menu-item--has-child');
      const target = event.target as HTMLElement;

      if(childMenus) {
        childMenus.forEach((childMenu) => {
          if(childMenu && !childMenu.contains(event.target as Node)) {
            childMenu.querySelector('.site-nav__child-menu')?.classList.remove('site-nav__child-menu--open');
          }
        });
      }
    }
    );

  }, []);

  return (
  <header className="site-header">
    <div className="container">
      <div className="site-header__locale-select">
        <LocalesSelectButton />
      </div>
      <div className="site-header__main">
        <p className="logo"><img src="/assets/img/logo.svg" alt="Glapon" /></p>
      </div>
    </div>          
  </header>
  );
}

export default Header;