
import React, { useEffect } from 'react';
import { useAtom } from "jotai";
import { scrollTo } from "../utils/functions";
import { localeAtom, translationAtom } from '../store/Locales';

function Footer() {

  const [t, setTranslation] = useAtom(translationAtom);

  useEffect(() => {

    window.addEventListener('scroll', () => {
      const backToTopButton = document.querySelector('.back-to-top-button');
      if(backToTopButton) {
        if(window.scrollY > 100) {
          backToTopButton.classList.add('visible');
        } else {
          backToTopButton.classList.remove('visible');
        }
      }
    });

  }, []);

  if (!t) {
    return <div></div>;
  }

  return (
  <footer className="site-footer">
    <div className="container container--fluid">
      <button type="button" onClick={ ()=>{scrollTo('#header')} } className="back-to-top-button"></button>
      <p className="copyright"><small>&copy; {t.layout.copyright}</small></p>   
    </div>
  </footer>
  );
}

export default Footer;