import React, { ChangeEvent, MouseEvent, useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { customEvent } from '../../gtm';
import { isNumber, getParameterByName, scrollTo } from "../../utils/functions";
import { Head } from '../../components/Head';
import LoadingBar from '../../components/LoadingBar';
import NotFoundContent from "../../components/NotFoundContent";
import { Coupon } from "../../types/Coupons";
import { lookup } from "../../api/CouponsAPI";
import usePageLoading from "../../hooks/UsePageLoading";
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../../store/Locales';
import { prevGroupAtom } from '../../store/Components';
import { getTextByLocale, truncateAndAppendDots } from "../../utils/functions";
import '../../style.scss';

interface DateObject {
  expired_at: string;
  valid_at: string;
}

function Coupons() {

  const { id } = useParams<{ id: string }>();
  const [locale] = useAtom(localeAtom);
  const [t, setTranslation] = useAtom(translationAtom);
  const [prevGroup, setPrevGroup] = useAtom(prevGroupAtom);
  const [coupon, setCoupon] = useState<any | null>(null);
  const [status, setStatus] = useState(null);
  const [used, setUsed] = useState(false);
  const [valid, setValid] = useState(true);
  // const [groupTitle, setGroupTile] = useState(prevGroup.title || '');
  // const [groupCode, setGroupCode] = useState(prevGroup.code || '');

  const fetchCoupon = async () => {
    const response = await lookup({
      coupon: id
    });

    setStatus(response.status);
    if(response.status === 200 && response.data){
      setCoupon(response.data);
      isCouponValid({
        expired_at: response.data.expired_at,
        valid_at: response.data.valid_at,
      });

      const tagManagerArgs = {
        dataLayer: {
          locale: locale,
          coupon_group_code: response.data.group && response.data.group.code ? response.data.group.code : null,
          coupon_group_name: response.data.group && response.data.group.name ? response.data.group.name : null,
          coupon_code: id ? id : null,
          coupon_name: response.data.languages ? getTextByLocale(response.data.languages, locale, 'headline', 'string') : null,
          places_id: response.data.place.id ? response.data.place.id : null,
          places_name: response.data.place.languages ? getTextByLocale(response.data.place.languages, locale, 'name', 'string') : null        
        },
        dataLayerName: 'coupon_loaded_event'
      };

      customEvent(tagManagerArgs);
    }

  }

  const isCouponValid = (dates: DateObject)=>{
    const currentDate = new Date();
    const validAt = dates.valid_at ? new Date(dates.valid_at) : '2100/01/01';
    const expiredAt = dates.expired_at ? new Date(dates.expired_at) : '1990/01/01';

    if (currentDate < validAt) {
      setValid(false);
      return false;
    } else if (currentDate > expiredAt) {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }

  }

  const getDeal = () => {

    if(used == true) return false;

    const tagManagerArgs = {        
      dataLayer: {
        locale: locale,
        coupon_group_code: coupon.group && coupon.group.code ? coupon.group.code : null,
        coupon_group_name: coupon.group && coupon.group.name ? coupon.group.name : null,
        coupon_code: id ? id : null,
        coupon_name: coupon.languages ? getTextByLocale(coupon.languages, locale, 'headline', 'string') : null,
        places_id: coupon.place.id ? coupon.place.id : null,
        places_name: coupon.place.languages ? getTextByLocale(coupon.place.languages, locale, 'name', 'string') : null,
        coupon_used: true
      },
      dataLayerName: 'coupon_used_event'
    };

    customEvent(tagManagerArgs);

    setUsed(true);
  }
  
  useEffect(() => {
    if(id){
      fetchCoupon();
    }

      setPrevGroup({
        title: null,
        code: null
      });

  }, [id]); // add dependency to re-run effect when `id` changes


  const previewKey = getParameterByName('pvcode');
  let previewParam = '';
  if(previewKey){
    previewParam = '?pvcode=' + previewKey;
  }

  if (!coupon || !t) {
    return (
      <>
        <LoadingBar />
      </>
    );
  }

  return (
    status == 200 ?
    <>
    <Head title={getTextByLocale(coupon.languages, locale, 'headline', 'string')} description="" path="page1" />
    <section className="contents-section">
      <div className="container">
        <nav className="breadcrumbs">
          { (coupon.group && coupon.group.code && coupon.group.name ) &&
          <Link to={`/groups/${coupon.group.code}${previewParam}`}>{coupon.group.name}</Link>
          }
          <span>{truncateAndAppendDots(getTextByLocale(coupon.languages, locale, 'headline'), 32)}</span>
        </nav>
        { (coupon.group && coupon.group.code && coupon.group.name) &&
          <p className="page-title"><Link to={`/groups/${coupon.group.code}${previewParam}`}>{coupon.group.name}</Link></p>
        }
        <div className="coupon mt-20">

          <div className="card">
            <div className="card__top">
              <div className="card__inner">
                <p className="card__subtitle">{getTextByLocale(coupon.place.languages, locale, 'name')}</p>
                <h1 className="card__title">{getTextByLocale(coupon.languages, locale, 'headline')}</h1>
                { coupon.status == 1 &&
                <>
                  {
                  coupon.expired_at &&
                  <div className="card__highlight">
                    <p className="card__highlight-label"><span className="expiration-icon">{t.general.coupon.effective_date}</span></p>
                    <p className="card__highlight-date">{coupon.expired_at}</p>
                  </div>
                  }
                  {
                  getTextByLocale(coupon.languages, locale, 'summary') && 
                  <div className="card__description">
                    {getTextByLocale(coupon.languages, locale, 'summary')}
                  </div>
                  }
                  <button type="button" onClick={getDeal} className={`button--primary use-coupon-button ${(used == true || valid == false) && 'use-coupon-button--used'}`} disabled={used || !valid}>
                    {
                      (used == true) ? t.general.coupon.used_coupon : valid == false ? t.general.coupon.not_valid : t.general.coupon.use_coupon
                    }
                  </button>
                </>
                }
              </div>
            </div>
            <div className="card__bottom">
              <div className="card__inner">
                { coupon.status == 2 &&
                  <div className="card__highlight mb-10">
                    <p className="card__highlight-date">{t.general.coupon.under_maintenance}</p>
                  </div>
                }
                { coupon.status == 1 &&
                <>
                  {
                  getTextByLocale(coupon.languages, locale, 'precautions') && 
                  <div className="card__highlight mb-10">
                    <p className="card__highlight-label">{t.general.coupon.terms}</p>
                    <p className="card__highlight-date">{getTextByLocale(coupon.languages, locale, 'precautions')}</p>
                  </div>
                  }

                  {
                  (coupon.expired_at || coupon.valid_at) &&
                  <div className="card__highlight">
                    <p className="card__highlight-label">{t.general.coupon.available_period}</p>
                    <p className="card__highlight-date">{coupon.valid_at} - {coupon.expired_at}</p>
                  </div>
                  }
                </>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    {coupon.status == 1 &&
    <section className="info-section">
      <div className="container">
        <h2 className="section__title">{t.general.place.place_info}</h2>

        <dl className="place-info mb-20">
          {
          getTextByLocale(coupon.place.languages, locale, 'name') &&
          <>
            <dt>
            {t.general.place.name}
            </dt>
            <dd>
            {getTextByLocale(coupon.place.languages, locale, 'name')}
            </dd>
            </>
          }
          {
          coupon.place.hp_url &&
          <>
            <dt>
            {t.general.place.website}
            </dt>
            <dd>
            <a href={coupon.place.hp_url} target="_blank" className="link-text">{coupon.place.hp_url}</a>
            </dd>
            </>
          }
          {
          coupon.place.phone &&
          <>
            <dt>
            {t.general.place.phone_number}
            </dt>
            <dd>
            {coupon.place.phone}
            </dd>
            </>
          }
          {
          getTextByLocale(coupon.place.languages, locale, 'business_hours') &&
          <>
            <dt>
            {t.general.place.hours}
            </dt>
            <dd>
            {getTextByLocale(coupon.place.languages, locale, 'business_hours')}
            </dd>
            </>
          }
          {
          getTextByLocale(coupon.place.languages, locale, 'regular_holiday') &&
          <>
            <dt>
            {t.general.place.holiday}
            </dt>
            <dd>
            {getTextByLocale(coupon.place.languages, locale, 'regular_holiday')}
            </dd>
            </>
          }
          {
          getTextByLocale(coupon.place.languages, locale, 'regular_holiday') &&
          <>
            <dt>
            {t.general.place.address}
            </dt>
            <dd>
            {coupon.place.zipcode}<br />
            {getTextByLocale(coupon.place.languages, locale, 'address1')}<br />
            {getTextByLocale(coupon.place.languages, locale, 'address2')}
            </dd>
            </>
          }
          {
          getTextByLocale(coupon.place.languages, locale, 'parking') &&
          <>
            <dt>
            {t.general.place.parking}
            </dt>
            <dd>
            {getTextByLocale(coupon.place.languages, locale, 'parking')}
            </dd>
            </>
          }
          {
          getTextByLocale(coupon.place.languages, locale, 'smoking') &&
          <>
            <dt>
            {t.general.place.smoking}
            </dt>
            <dd>
            {getTextByLocale(coupon.place.languages, locale, 'smoking')}
            </dd>
            </>
          }
          {
          coupon.place.reservations.length > 0 &&
          <>
            <dt>
            {t.general.place.reservation}
            </dt>
            <dd>
            {
              coupon.place.reservations.map((reservation: any, i: number) => (
                <p key={i}>{reservation.name[locale]}</p>
              ))
            }
            </dd>
            </>
          }
        </dl>

        <button type="button" onClick={getDeal} className={`button--primary use-coupon-button ${(used == true || valid == false) && 'use-coupon-button--used'}`} disabled={used || !valid}>
          {
            (used == true) ? t.general.coupon.used_coupon : valid == false ? t.general.coupon.not_valid : t.general.coupon.use_coupon
          }
        </button>

        { (coupon.group && coupon.group.code) &&
        <Link to={`/groups/${coupon.group.code}${previewParam}`} className="button--secondary back-button mt-10">{t.layout.back_to_list}</Link>
        }

      </div>
    </section>
    }
    </>
    :
    <section className="contents-section">
      <div className="container">
        <NotFoundContent />
      </div>
    </section>
  );
}

export default Coupons;
