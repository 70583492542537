import axios, { AxiosResponse, AxiosError } from 'axios';
import { getParameterByName } from '../utils/functions'
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;


export async function get(url: string, params: any = {}): Promise<any> {

  const previewKey = getParameterByName('pvcode');
  if(previewKey){
    params.pvcode = previewKey;
  }

  try {
    const response = await axios.get(url, { params: params });
    return response;
  } catch (error) {
    return (error as AxiosError).response ?? error as AxiosError;
  }
}

export async function post(url: string, params: any = {}): Promise<any> {
  try {
    await axios.get('/sanctum/csrf-cookie');
    const response = await axios.post(url, params);
    return response;
  } catch (error) {
    return (error as AxiosError).response ?? error as AxiosError;
  }
}

export async function put(url: string, params: any = {}): Promise<any> {
  try {
    await axios.get('/sanctum/csrf-cookie');
    const response = await axios.put(url, params);
    return response;
  } catch (error) {
    return (error as AxiosError).response ?? error as AxiosError;
  }
}

export async function destroy(url: string, params: any = {}): Promise<any> {
  try {
    await axios.get('/sanctum/csrf-cookie');
    const response = await axios.delete(url, { data: params });
    return response;
  } catch (error) {
    return (error as AxiosError).response ?? error as AxiosError;
  }
}
