
import React, { FC, useEffect } from 'react';
import LocalesSelectButton from './LocalesSelectButton';
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';
import { getCookie, setCookie } from "../utils/functions";

type Props = {
  agreeAction?: any;
};

const Agreement: FC<Props> = ({ agreeAction }: Props) => {
  
  const [t, setTranslation] = useAtom(translationAtom);

  useEffect(() => {

    window.addEventListener('scroll', () => {
      const backToTopButton = document.querySelector('.back-to-top-button');
      if(backToTopButton) {
        if(window.scrollY > 100) {
          backToTopButton.classList.add('visible');
        } else {
          backToTopButton.classList.remove('visible');
        }
      }
    });

  }, []);


  if (!t) {
    return <div></div>;
  }

  return (
    
  <div className="agreement">
    <div className="contaienr">
      <div className="text--right mb-20">
        <LocalesSelectButton />
      </div>
      <h1 className="content-title text--center"><span className="logo"><img src="/assets/img/logo.svg" alt="Glapon" /></span></h1>
      <p className="text--center text--l text--bold mt-10">{t.general.agreement.confirm}</p>

      <h2>{t.general.agreement.terms_title}</h2>
     
      <div dangerouslySetInnerHTML={{ __html: t.general.agreement.terms_body }} />

      <h2>{t.general.agreement.cookie_title}</h2>
      
      <div dangerouslySetInnerHTML={{ __html: t.general.agreement.cookie_body }} />

      <div className="mt-20 mb-30"><button type="button" onClick={()=>{
        setCookie('termagreement', 'true', 30);
        agreeAction();
      }} className="button--primary">{t.general.agreement.agree}</button></div>
      <p className="copyright text--center text--s">&copy; {t.layout.copyright}</p>

    </div>
  </div>
  );
}

export default Agreement;