import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Head } from '../../components/Head';
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../../store/Locales';
import NotFoundContent from "../../components/NotFoundContent";

function NoMatch() {

  const [t, setTranslation] = useAtom(translationAtom);
  
  return (

    <>
      <Head title={t.general.not_found.title} description="" path="" />
      <div className="container">
        <NotFoundContent />
      </div>
    </>

  );
}

export default NoMatch;
