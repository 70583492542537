import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';

const NotFoundContent = () => {

  const [t, setTranslation] = useAtom(translationAtom);

  return (
    <>
      <h1 className="page-title text--center">{t.general.not_found.title}</h1>
      <div className="page-contents">
        <p className="text--xl text--bold text--black mb-20">{t.general.not_found.headline}</p>
        <p>{t.general.not_found.text}</p>
      </div>
    </>
  );

};

export default NotFoundContent;
