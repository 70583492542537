import { FocusEvent, ChangeEvent } from 'react';
import axios from "axios";
import Validator from 'validatorjs';

// import _Validatorjs from "validatorjs";

// const Validator = _Validatorjs as unknown as typeof _Validatorjs.default;


const copyInputText = (e: FocusEvent<HTMLInputElement>) => {
  const input = e.target;
  input.select();
  document.execCommand("copy");
  input.setSelectionRange(0, 0);
};

const prefectures = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県"
];

// const getAddressFromZipcode = async (e: FocusEvent<HTMLInputElement>) => {

//   const formatedZipcode = e.target.value.replace('-', '');
//   if (formatedZipcode.length === 7) {
//     console.log(formatedZipcode)
//     try {
//       const res = await axios.get(
//         "https://zipcloud.ibsnet.co.jp/api/search",
//         {
//           params: {
//             zipcode: formatedZipcode
//           }
//         }
//       );

//       if (res.data.results) {
//         const result = res.data.results[0];
//         return result;
//       }
//     } catch {
//       alert("住所の取得に失敗しました。");
//     }
//   }
// };  

const formatZipCode = (e: ChangeEvent<HTMLInputElement>) => {

  let inputValue = e.target.value;

  // Remove any characters that are not numbers
  inputValue = inputValue.replace(/[^0-9]/g, '');

  // Limit the length to 8 characters
  inputValue = inputValue.slice(0, 8);

  // Add a hyphen after the 3rd number if it's not already present
  if (
    inputValue.length >= 3 &&
    inputValue.charAt(3) !== '-' &&
    (e as any).nativeEvent.inputType !== 'deleteContentBackward'
  ) {
    inputValue = inputValue.slice(0, 3) + '-' + inputValue.slice(3);
  }
  
  return inputValue;

};



const getPrefectureOptions = (response:any) => {
  const prefectureOptions = response.map((prefecture:any) => (
    <option key={prefecture.id} value={prefecture.id}>
      {prefecture.ja}
    </option>
  ));
  
  prefectureOptions.unshift(
    <option key="0" value="">
      選択してください
    </option>
  );

  return prefectureOptions;

};

const formatID = (id:any) => {

  let numericId = id === 'new' ? 0 : id;

  const regex = /^[0-9]*$/;
  if(regex.test(numericId)){
    return Number(numericId);
  } else {
    return -1;
  }
}

const isNumber = (value: any): boolean => {
  return typeof value === 'number' && !isNaN(value);
}


const getParameterByName = (name:string, url:string | null = null) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  let results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const validation = (param:any, rules:any)=>{

  // Validator.register('telephone', function(value, requirement, attribute) { // requirement parameter defaults to null
  //   return value.match(/^(?:\+?\d+-)?\d+(?:-\d+){2}$|^\+?\d+$/);
  // }, ':attributeの形式が正しくありません。');

  // Validator.register('zipcode', function(value, requirement, attribute) { // requirement parameter defaults to null
  //   return value.match(/^(([0-9]{3}-[0-9]{4})|([0-9]{7}))$/);
  // }, ':attributeの形式が正しくありません。');

  // Validator.register('hurigana', function(value, requirement, attribute) { // requirement parameter defaults to null
  //   return value.match(/^[ァ-ヶー　 ]+$/);
  // }, ':attributeはカタカナで入力してください。');

  // Validator.register('image', function(value, requirement, attribute) { // requirement parameter defaults to null
  //   if(value.name){
  //     return value.name.match(/\.(jpe?g|png)$/i);
  //   } else {
  //     return true;
  //   }
  // }, 'アップロードできる画像のフォーマットはjpgかpngのみです。');

  // Validator.register('filesize', function(value:any, requirement:any, attribute:any) { // requirement parameter defaults to null    
  //   return value.size <= requirement;
  // }, 'ファイルサイズが大きすぎます。');  

  let res:any = {};

	let errorMessages = {};

	
  errorMessages = {
    required: ':attributeを入力してください。',
    "required.prefecture_id" : ':attributeを選択してください。',
    "required.company_id" : ':attributeを選択してください。',
    "required.area_id" : ':attributeを選択してください。',
    "required.country_id" : ':attributeを選択してください。',
    "required.media_id" : ':attributeを選択してください。',
    "required.coupon_id" : ':attributeを選択してください。',
    "required.role_id" : ':attributeを選択してください。',
    "required.category_id" : ':attributeを選択してください。',
    accepted: ':attributeに同意してください。',
    email: 'メールアドレスの形式が正しくありません。',
    max: ':attributeは:max文字以内で入力してください。',
    min: ':attributeは:min文字以上で入力してください。',
    confirmed: ':attribute（確認用）と一致していません。'
  }


  Validator.register('password', (value:any, requirement:any, attribute:any) => { // requirement parameter defaults to null
    return value.match(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,32}$/);
  }, ':attributeは8文字以上で、半角英数字、記号を含めてください。');

  let validation = new Validator(param, rules, errorMessages);


  if (validation.fails()) {
    res.result = false;
		res.errors = validation.errors.all();
		return res;
  } else {
    res.result = true;
    return(res);
  }
}

const scrollToPosition = (position: number, duration: number, easing: (progress: number) => number): void => {
  const start = window.pageYOffset || document.documentElement.scrollTop;
  const change = position - start;
  const startDate = performance.now();

  const animateScroll = (timestamp: number) => {
    const currentTime = timestamp - startDate;
    const scrollProgress = Math.min(currentTime / duration, 1);
    const easedProgress = easing(scrollProgress);
    const scrollTop = start + change * easedProgress;

    window.scrollTo(0, scrollTop);

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

const easeOutEasing = (progress: number): number => {
  return 1 - Math.pow(1 - progress, 2);
};

const easeOutExpoEasing = (progress: number): number => {
  return progress === 1 ? 1 : 1 - Math.pow(2, -10 * progress);
};

const scrollTo = (tar: string, e?: Event): void => {
  const path = window.location.pathname;
  let target = tar.replace(path, '');
  target = target.replace('#', '');

  if (document.querySelector(`[data-id="${target}"]`) || target === 'header') {
    if (e) e.preventDefault();

    const diff = 0;
    const position = target === 'header' ? 0 : document.querySelector(`[data-id="${target}"]`)!.getBoundingClientRect().top + window.pageYOffset - diff;

    scrollToPosition(position, 400, easeOutExpoEasing);
  }
};

const getValueFromPath = (obj:any, path:string) => {
  let pathParts = path.split('.');
  let current = obj;
  for (let part of pathParts) {
    if (current[part] === undefined) {
      return undefined;
    } else {
      current = current[part];
    }
  }
  return current;
}

const getPostStatusNumber = (str:string|null) => {
  if(str == 'unpublish'){
    return 0;
  } else if (str == 'publish') {
    return 1;
  } else if (str == 'maintenance') {
    return 2;
  } else if (str == 'delete') {
    return 9;
  }
  return null;
}

const getPostStatusCode = (num:number|null) => {
  if(num == 0){
    return 'unpublish';
  } else if (num == 1) {
    return 'publish';
  } else if (num == 2) {
    return 'maintenance';
  } else if (num == 9) {
    return 'delete';
  }
  return null;
}

const getPostStatusText = (num:number|null) => {
  if(num == 0){
    return '非公開'
  } else if (num == 1) {
    return '公開';
  } else if (num == 2) {
    return 'メンテナンス';
  } else if (num == 9) {
    return '削除';
  }
  return null;
}


const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const getTextByLocale = (ary: any, locale: string, key: string, type?: string) => {
  
  const defaultObj = ary.filter((item:any) => item.language === 'ja');
  const obj = ary.filter((item:any) => item.language === locale);

  if(obj && obj.length && obj[0][key]){
    return (
      type === 'string' 
      ? 
      obj[0][key]
      : 
      <>
          {obj[0][key].split('\n').map((line:any, index:any) => 
              <span key={index}>
                  {line}
                  <br />
              </span>
          )}
      </>      
  );
  } else if(defaultObj && defaultObj.length && defaultObj[0][key]) {
    return (
      type === 'string' 
      ? 
      defaultObj[0][key]
      : 
      <>
        {defaultObj[0][key].split('\n').map((line:any, index:any) => 
            <span key={index}>
                {line}
                <br />
            </span>
        )}
      </>      
      
  );
  } else {
    return '';
  }

}


function getByteLength(s: string): number {
  let length = 0;
  for(let i = 0; i < s.length; i++) {
      const c = s.charAt(i);
      if (encodeURI(c).length > 2) {
          length += 2;
      } else {
          length += 1;
      }
  }
  return length;
}

function truncateAndAppendDots(str: string, length: number = 10): string {
  let byteCount = 0;
  for(let i = 0; i < str.length; i++) {
      const c = str.charAt(i);
      const byteLength = encodeURI(c).length > 2 ? 2 : 1;
      if(byteCount + byteLength > length) {
          return str.slice(0, i) + '...';
      }
      byteCount += byteLength;
  }
  return str;
}


export {
	copyInputText,
//  getAddressFromZipcode,
  getPrefectureOptions,
  formatZipCode,
  formatID,
  isNumber,
  getParameterByName,
  validation,
  scrollTo,
  getValueFromPath,
  getPostStatusNumber,
  getPostStatusCode,
  getPostStatusText,
  setCookie,
  getCookie,
  getTextByLocale,
  truncateAndAppendDots
}