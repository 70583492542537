import { post, get, put, destroy } from './ApiClient';
import { Priority } from '../types/Locales';

export const getPriority = async () => {
  const response = await get('/api/languages/priority');
  return response;
};

export const getTranslation = async (locale:string) => {
  const response = await get(`/locales/${locale}.json`);
  return response;
};

// export const lookup = async (params: GroupLookup) => {
//   const response = await get('/api/groups/lookup', params);
//   return response;
// };

// export const create = async (params: GroupCreate) => {
//   const response = await post('/api/groups/create', params);
//   return response;
// };

// export const update = async (params: GroupUpdate) => {
//   const response = await put('/api/groups/update', params);
//   return response;
// };

// export const remove = async (params: GroupDelete) => {
//   const response = await destroy('/api/groups/destroy', {
//     group_id: params
//   });
//   return response;
// };