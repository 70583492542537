import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { customEvent } from '../../gtm';
import { Head } from '../../components/Head';
import LoadingBar from '../../components/LoadingBar';
import NotFoundContent from "../../components/NotFoundContent";
import { CouponCard } from "../../components/CouponCard";
import { Group, Coupon } from "../../types/Groups";
import { lookup } from "../../api/GroupsAPI";
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../../store/Locales';
import '../../style.scss';

function Groups() {

  const { id } = useParams<{ id: string }>();
  const [locale] = useAtom(localeAtom);
  const [groupData, setGroupData] = useState<Group | null>(null);
  const [status, setStatus] = useState(null);

  const fetchGroup = async () => {
    const response = await lookup({
      group: id
    });

    setStatus(response.status);
    if(response.status === 200 && response.data){
      setGroupData(response.data);

      const tagManagerArgs = {
        dataLayer: {
          locale: locale,
          coupon_group_code: id ? id : null,
          coupon_group_name: response.data.name ? response.data.name : null,    
        },
        dataLayerName: 'group_loaded_event'
      };

      customEvent(tagManagerArgs);

    }
  }

  useEffect(() => {
    if(id){
      fetchGroup();
    }

  }, [id]); // add dependency to re-run effect when `id` changes

  if (!groupData) {
    return (
      <>
        <LoadingBar />
      </>
    );
  }

  return (
    <>      
      <Head title={groupData.name || ''} description="" path="" />
      <section className="contents-section">
        <div className="container">
          {
            status == 200 ? 
            <>
            {groupData &&
            <h1 className="page-title">{groupData.name || ''}</h1>
            }
            <div className="coupons">
              {
                groupData && 
                groupData.coupons.map((coupon: Coupon, i: number) => (
                  <CouponCard key={i} coupon={coupon} code={id} groupName={groupData.name} /> // pass down coupon data to CouponCard component
                ))
              }
            </div>
            </>
            :
            <NotFoundContent />
          }
        </div>
    </section>
    </>
  );
}

export default Groups;
