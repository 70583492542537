import React, { FC, MouseEvent } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { getPostStatusText } from "../utils/functions";
import { Group, Coupon } from "../types/Groups";
import { getTextByLocale, getParameterByName } from "../utils/functions";
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';
// import { prevGroupAtom } from '../store/Components';

type Data = {
  id: number;
  title: string;
  subtitle?: string;
};

type Props = {
  code: string | undefined;
  groupName: string | undefined;
  coupon: Coupon;
};

export const CouponCard: FC<Props> = ({ coupon, code, groupName }: Props) => {

  const [locale] = useAtom(localeAtom);
  const [t, setTranslation] = useAtom(translationAtom);
  //const [prevGroup, setPrevGroup] = useAtom(prevGroupAtom);

  // const savePrevGroup = ()=>{

  //   setPrevGroup(
  //     {
  //       title: groupName,
  //       code: code
  //     }
  //   );

  // }

  const previewKey = getParameterByName('pvcode');
  let previewParam = '';
  if(previewKey){
    previewParam = '?pvcode=' + previewKey;
  }

  if (!t) {
    return <div></div>;
  }

  return (
    <div className="card">
      <div className="card__top">
        <div className="card__inner">
          <p className="card__subtitle">{getTextByLocale(coupon.place, locale, 'name')}</p>
          <p className="card__title">{getTextByLocale(coupon.data, locale, 'headline')}</p>
          {
            coupon.status == 1 &&
            <>
            {
            coupon.expired_at && 
            <div className="card__highlight">
              <p className="card__highlight-label"><span className="expiration-icon">{t.general.coupon.effective_date}</span></p>           
              <p className="card__highlight-date">{coupon.expired_at}</p>            
            </div>
            }
            </>
          }
        </div>
      </div>
      <div className="card__bottom">
        <div className="card__inner">
          {
          coupon.status == 2 ?
          <p className="button--primary button--primary--disabled">{t.general.coupon.under_maintenance}</p>
          :
          <Link to={`/coupons/${coupon.code}${previewParam}`} className="button--primary">{t.general.coupon.check_coupon}</Link>
          }
        </div>
      </div>
    </div>
  );
};
