import React, { useContext, useEffect } from 'react';
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';
import { getPriority, getTranslation } from '../api/LocalesAPI';
import { LocaleModalContext } from '../pages/Layout';

function LocalesSelectButton() {

  const [locale, setLocale] = useAtom(localeAtom);
  const [t, setTranslation] = useAtom(translationAtom);
  const { openLocaleModal, closeLocaleModal } = useContext(LocaleModalContext);

  const showLocaleSelect = ()=>{
    openLocaleModal();
  }

  useEffect(() => {
    // console.log(locale)
  }, []);


  if (!t) {
    return <div>Loading...</div>;
  }

  return (
    <button type="button" onClick={showLocaleSelect} className="locale-select-button">{t.layout.locales.select_locale}：<span className="selected">{t.layout.locales.locale_titles[locale]}</span></button>
  );
}

export default LocalesSelectButton;
