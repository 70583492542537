import React, {useEffect,useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import Layout from "./pages/Layout";
import NoMatch from './pages/NoMatch';
import LoadingBar from './components/LoadingBar';
import Home from './pages/Home';
import Groups from './pages/Groups';
import Coupons from './pages/Coupons';
import useDialog from "./hooks/UseDialog";
import Dialog from "./components/Dialog";
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from './store/Locales';
import useLocales from "./hooks/UseLocales";



function App() {

  const [locale, setLocale] = useAtom(localeAtom);
  const { fetchLocalePriorty, fetchTranslation } = useLocales();
  const [ priorityChecked, setPriorityChecked ] = useState(false);

  useEffect(() => {

    const init = async () => {
      await fetchLocalePriorty();
      setPriorityChecked(true);
    };
    init();

  }, []);

  if (!priorityChecked) {
    return <div><LoadingBar /></div>;
  }

  return (
    <>
    <Dialog />
    <Router>
      <Routes>          
        <Route path="/" element={<Layout />}>
          <Route path="*" element={<NoMatch />} />
          <Route path="/" element={<NoMatch />} />
          <Route path="/groups/:id" element={<Groups />} />
          <Route path="/coupons/:id" element={<Coupons />} />
        </Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
