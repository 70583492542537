import { post, get, put, destroy } from './ApiClient';
import { Coupon, CouponCreate, CouponUpdate, CouponLookup, CouponDelete, CouponSearchQuery } from '../types/Coupons';

// export const search = async (params: CouponSearchQuery) => {
//   const response = await get('/api/coupons/search', params);
//   return response;
// };

export const lookup = async (params: CouponLookup) => {
  const response = await get('/api/coupons/detail', params);
  return response;
};

// export const create = async (params: CouponCreate) => {
//   const response = await post('/api/coupons/create', params);
//   return response;
// };

// export const update = async (params: CouponUpdate) => {
//   const response = await put('/api/coupons/update', params);
//   return response;
// };

// export const remove = async (params: CouponDelete) => {
//   const response = await destroy('/api/coupons/destroy', {
//     coupon_id: params
//   });
//   return response;
// };