import React, { useContext, useEffect } from 'react';
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';
import useLocales from "../hooks/UseLocales";
import { LocaleModalContext } from '../pages/Layout';

function LocalesSelect() {

  const [locale, setLocale] = useAtom(localeAtom);
  const [t, setTranslation] = useAtom(translationAtom);
  const { openLocaleModal, closeLocaleModal } = useContext(LocaleModalContext);

  const { fetchLocalePriorty, fetchTranslation, changeLocale } = useLocales();
  //const [ priorityChecked, setPriorityChecked ] = useState(false);

  // const fetchLocalePriorty = async () => {
  //   const response = await getPriority();
  //   if(response.status === 200 && response.data.language){
  //     setLocale(response.data.language);
  //     fetchTranslation(response.data.language);
  //   } else {
  //     fetchTranslation();
  //   }
  // };

  // const fetchTranslation = async (localeTitle = 'ja') => {
  //   const response = await getTranslation(localeTitle);
  //   if(response.status === 200 && response.data){
  //     setTranslation(response.data);
  //   }
  // };

  const updateLocale = (newLocale:string) => {
    changeLocale(newLocale);
    closeLocaleModal();
  }

  useEffect(() => {
    //fetchLocalePriorty();
  }, []);

  const localeTitle = t && t.layout && t.layout.locales && t.layout.locales.locale_titles 
    ? t.layout.locales.locale_titles[locale] 
    : '';

  if (!t) {
    return <div></div>;
  }

  return (
    <>
      <ul className="locale-menu">
        <li><button type="button" data-locale="ja" onClick={() => updateLocale('ja')} className="locale-menu__button">{t.layout.locales.locale_titles.ja}</button></li>
        <li><button type="button" data-locale="en" onClick={() => updateLocale('en')} className="locale-menu__button">{t.layout.locales.locale_titles.en}</button></li>
        <li><button type="button" data-locale="zh-tw" onClick={() => updateLocale('zh-tw')} className="locale-menu__button">{t.layout.locales.locale_titles["zh-tw"]}</button></li>
        <li><button type="button" data-locale="ko" onClick={() => updateLocale('ko')} className="locale-menu__button">{t.layout.locales.locale_titles.ko}</button></li>
      </ul>
      <button type="button" onClick={closeLocaleModal} className="close-locale-select-button"><span className="close-icon"><img src="../assets/img/icon_cross_02.svg" alt="" /></span>{t.general.locale_select.close}</button>
    </>
  );
}

export default LocalesSelect;
