import { get } from '../api/ApiClient';
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';
import { getPriority, getTranslation } from '../api/LocalesAPI';
import { getCookie, setCookie } from "../utils/functions";

const useLocales = () => {
  const [locale, setLocale] = useAtom(localeAtom);
  const [t, setTranslation] = useAtom(translationAtom);
  
  const fetchLocalePriorty = async () => {

    const defaultLocale = getCookie('defaultlocale') ? getCookie('defaultlocale') : '';

    if(defaultLocale){
      
      changeLocale(defaultLocale || 'ja');

    } else {

      const response = await getPriority();

      if(response.status === 200 && response.data.language){
        changeLocale(response.data.language);
        return true;
      }

    }
  };

  const fetchTranslation = async (key = 'ja') => {
    const response = await getTranslation(key);
    if(response.status === 200 && response.data){
      setTranslation(response.data);
      return true;
    } else {
      return false;
    }
  };

  const changeLocale = async (key: string)=>{
    setLocale(key);
    setCookie('defaultlocale', key, 30);
    const response = await fetchTranslation(key);

    return response;
  }


	return { fetchLocalePriorty, fetchTranslation, changeLocale };

};
export default useLocales;