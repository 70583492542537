import { useEffect } from 'react';
import { useAtom } from "jotai";
import { noScrollAtom } from '../store/Layout';

const addBodyClass = (className: string): void => {
  document.body.classList.add(className);
};

const removeBodyClass = (className: string): void => {
  document.body.classList.remove(className);
};

const useBodyClass = (): void => {

  const [noScroll, setNoScroll] = useAtom(noScrollAtom);

  useEffect(() => {
    if (noScroll) {
      addBodyClass('no-scroll');
    } else {
      removeBodyClass('no-scroll');
    }
  }, [noScroll]);
	
};

export default useBodyClass;
