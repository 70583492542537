import React, { ReactNode, useState, useEffect } from 'react';
import { atom } from "jotai";

interface DialogProps {
  visible: boolean;
  title?: string;
  body?: ReactNode;
  confirmAction: (value: string | PromiseLike<string>) => void | undefined
  confirmText?: string;
}

interface PrevGroupProps {
  title?: string | null;
  code?: string | null;
}

const dialogAtom = atom<DialogProps>({
  title: '',
  body: undefined,
  confirmAction: () => {},
  confirmText: '',
  visible: false
});


const prevGroupAtom = atom<PrevGroupProps>({
  title: null,
  code: null
});

export { dialogAtom, prevGroupAtom };