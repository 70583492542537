import React, { useEffect, useState,} from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Agreement from '../components/Agreement';
import LocalesSelect from '../components/LocalesSelect';
import { Outlet } from "react-router-dom";
import useModal from '../hooks/UseModal';
import { getCookie, setCookie } from "../utils/functions";
import { useAtom } from "jotai";
import { localeAtom, translationAtom } from '../store/Locales';

const LocaleModalContext = React.createContext({
  openLocaleModal: () => {},
  closeLocaleModal: () => {},
});

const Layout = () => {

  const [open, setOpen] = useState(true);
  const { Modal, openModal, closeModal } = useModal();
  const { Modal: LocaleModal, openModal: openLocaleModal, closeModal: closeLocaleModal } = useModal();
  const [t, setTranslation] = useAtom(translationAtom);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {

    const termAgreement = getCookie('termagreement');
    
    if(!termAgreement){
      openModal();
    }
    
  } ,[]);


  if (!t) {
    return <div></div>;
  }

  return (
    <>
      <LocaleModalContext.Provider value={{ openLocaleModal, closeLocaleModal }}>
        <div className="site-wrapper">
          <div className="wave"></div>
          <Header />
          <main className="site-trunk">
            <Outlet />
          </main>
          <Footer />
          <Modal header={false} size="fluid">
            <Agreement agreeAction={closeModal} />
          </Modal>
          <LocaleModal title={t.general.locale_select.select_language} header={true} size="sm" styles="locale-select">
            <LocalesSelect />
          </LocaleModal>
        </div>
      </LocaleModalContext.Provider>
    </>
  );
};

export default Layout;
export { LocaleModalContext };