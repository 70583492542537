import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.scss';
import App from './App';
import { Provider } from "jotai";
import reportWebVitals from './reportWebVitals';
// import './gtm';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const helmetContext = {};

root.render(
  //<React.StrictMode>
  <Provider>
    <HelmetProvider>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
      </Helmet>
      <App />
    </HelmetProvider>
  </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
