import React, { ReactNode, useState, useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { dialogAtom } from '../store/Components';
import { noScrollAtom } from '../store/Layout';
import useBodyClass from './UseBodyClass';

interface DialogProps {
  visible?: boolean;
  title?: string;
  body?: ReactNode;
  confirmAction: (value: string | PromiseLike<string>) => void | undefined
  confirmText?: string;
}

const useDialog = () => {
  const [dialog, setDialog] = useAtom(dialogAtom);
  const [noScroll, setNoScroll] = useAtom(noScrollAtom);

  useBodyClass();

  const open = useCallback((props: DialogProps) => {
    const { title, body, confirmAction, confirmText } = props;

    setNoScroll(true);
    setDialog((prevDialog: DialogProps) => ({
      ...prevDialog,
      visible: true,
      title: title,
      body: body,
      confirmAction: confirmAction,
      confirmText: confirmText ?? 'はい',
    }));

  }, []);

  const close = () => {
    setDialog((prevDialog: DialogProps) => ({
      ...prevDialog,
      visible: false,
    }));
    setNoScroll(false);
  };

  const cancel = () => {
    return false;
  };

  useEffect(() => {
    // Additional code or side effects can be added here if needed
  }, []);

  return { open, close, cancel };
};

export default useDialog;
