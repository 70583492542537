import { post, get, put, destroy } from './ApiClient';
import { Group, GroupLookup, GroupDelete } from '../types/Groups';

// export const search = async (params: SearchQuery) => {
//   const response = await get('/api/groups/search', params);
//   return response;
// };

export const lookup = async (params: GroupLookup) => {
  const response = await get('/api/coupons/list', params);
  return response;
};

// export const create = async (params: GroupCreate) => {
//   const response = await post('/api/groups/create', params);
//   return response;
// };

// export const update = async (params: GroupUpdate) => {
//   const response = await put('/api/groups/update', params);
//   return response;
// };

// export const remove = async (params: GroupDelete) => {
//   const response = await destroy('/api/groups/destroy', {
//     group_id: params
//   });
//   return response;
// };